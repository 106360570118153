<template>
	<v-container class="pt-0 mt-0">
		<v-form
			ref="form"
			v-model="valid">
			<div class="mt-3 mb-0 pb-0">
				<v-icon
					v-if="form.created_from || form.created_to"
					@click="clearCreated"
					class="primary--text">
					mdi-close
				</v-icon>
				Created
			</div>
			<v-row class="py-0 my-0">
				<v-col
					class="py-0 my-0"
					cols="6">
					<DatetimePicker
						title="After"
						:createdNow="false"
						:key="form.created_from"
						:min="timeEpoch"
						:max="createdDateMax"
						v-model="form.created_from"></DatetimePicker>
				</v-col>
				<v-col
					class="py-0 my-0"
					cols="6">
					<DatetimePicker
						title="Before"
						:createdNow="false"
						:key="form.created_to"
						:min="createdDateMin"
						v-model="form.created_to"></DatetimePicker>
				</v-col>
				<v-col cols="12">
					<div class="mt-0 mb-0 pb-0">
						<v-icon
							v-if="form.collected_from || form.collected_to"
							@click="clearCollected"
							class="primary--text">
							mdi-close
						</v-icon>
						Collected
					</div>
				</v-col>
				<v-col
					class="py-0 my-0"
					cols="6">
					<DatetimePicker
						:disabled="this.form.showUncollected && !this.form.showCollected"
						title="After"
						:min="timeEpoch"
						:createdNow="false"
						:key="form.collected_from"
						:max="collectedDateMax"
						v-model="form.collected_from"></DatetimePicker>
				</v-col>
				<v-col
					class="py-0 my-0"
					cols="6">
					<DatetimePicker
						:disabled="this.form.showUncollected && !this.form.showCollected"
						title="Before"
						:createdNow="false"
						:key="form.collected_to"
						:min="collectedDateMin"
						v-model="form.collected_to"></DatetimePicker>
				</v-col>
				<v-col>
					<EditComponent
						noDialog
						v-if="hasLevels"
						:key="groupList.length"
						v-model="form.selectedGroups"
						:fullArray="groupList"
						:loading="filterLoading || loading"
						:width="500"
						title="Edit Groups"
						placeholderText="Groups"
						color="primary"></EditComponent>

					<EditComponent
						noDialog
						v-if="hasLevels"
						:key="filteredLevelList.length"
						v-model="form.selectedLevels"
						:fullArray="filteredLevelList"
						:loading="filterLoading || loading"
						:width="500"
						title="Edit Levels"
						placeholderText="Levels"
						color="primary"></EditComponent>

					<v-row>
						<v-col cols="6">
							<v-text-field
								type="number"
								:rules="[rules.positiveInteger, rules.lowerThanTo]"
								clearable
								@click:clear="form.amount_from = undefined"
								@change="
									(e) =>
										Number.parseInt(e) >= 0
											? (form.amount_from = Number.parseInt(e))
											: (form.amount_from = undefined)
								"
								:value="form.amount_from"
								label="Amount from"></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								clearable
								type="number"
								:rules="[rules.positiveInteger, rules.higherThanFrom]"
								@click:clear="form.amount_to = undefined"
								@change="
									(e) =>
										Number.parseInt(e) >= 0
											? (form.amount_to = Number.parseInt(e))
											: (form.amount_to = undefined)
								"
								:value="form.amount_to"
								label="Amount to"></v-text-field>
						</v-col>
					</v-row>

					<EditComponent
						noDialog
						v-model="form.selectedCurrencies"
						:fullArray="currencyCodes"
						:loading="filterLoading || loading"
						title="Edit Currencies"
						placeholderText="Currencies"
						color="primary"></EditComponent>

					<v-autocomplete
						multiple
						clearable
						deletable-chips
						small-chips
						label="Categories"
						v-model="categories"
						:items="allowedCategories">
						<template #item="{ item, on, attrs }">
							<v-list-item
								:disabled="categoryItemCount(item) === 0"
								v-on="on"
								v-bind="attrs">
								<v-list-item-content>
									<v-list-item-title>
										<div>{{ item }}</div>
									</v-list-item-title>
									<v-spacer></v-spacer>
									<v-list-item-subtitle>
										<v-chip
											small
											:color="`${categories.includes(item) ? 'success' : ''}`">
											{{ inCasinosCount(item) }}/{{ categoryItemCount(item) }}
										</v-chip>
									</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-row>
										<v-btn
											@click.stop.prevent="addCategoryCasinos(item)"
											class="mr-2 elevation-0"
											fab
											color="primary"
											width="20"
											height="20">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
										<v-btn
											@click.stop.prevent="removeCategoryCasinos(item)"
											class="mr-1 elevation-0"
											color="primary"
											fab
											width="20"
											height="20">
											<v-icon>mdi-minus</v-icon>
										</v-btn>
									</v-row>
								</v-list-item-action>
							</v-list-item>
						</template>
					</v-autocomplete>

					<EditComponent
						noDialog
						v-model="form.selectedCasinos"
						:fullArray="filteredCasinos"
						:searchIn="[`casino_id`]"
						valueKey="casino_id"
						labelKey="website"
						:width="500"
						title="Edit Casino List"
						placeholderText="Casinos"
						color="primary">
						<template #appendItem="{ item }">
							<v-chip small>ID: {{ item.casino_id }}</v-chip>
						</template>
					</EditComponent>
					<v-row class="d-flex justify-center mt-2">
						<v-checkbox
							v-model="form.showUncollected"
							:disabled="this.form.collected_from || this.form.collected_to"
							inset
							class="mt-0 mr-3">
							<template v-slot:label>
								<span class="ml-3">Show Uncollected</span>
							</template>
						</v-checkbox>
						<v-checkbox
							v-model="form.showCollected"
							inset
							class="mt-0">
							<template v-slot:label>
								<span class="ml-3">Show Collected</span>
							</template>
						</v-checkbox>
					</v-row>
				</v-col>
			</v-row>
		</v-form>
		<div class="d-flex justify-center mt-2">
			<v-btn
				:disabled="filterLoading || loading || !valid"
				:loading="filterLoading || loading"
				class="primary"
				@click="emitFilter">
				Apply
			</v-btn>
		</div>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { currencyCodes } from "../../constants/currencyCodes";
import DatetimePicker from "../forms/DatetimePicker.vue";
import EditComponent from "../jackpots/EditComponent.vue";
import { rules as defaultRules } from "../../plugins/rules.ts";
import permissions from "../../mixins/permissions";
import _ from "lodash";

export default {
	model: {
		event: "filter",
		prop: "filter",
	},
	props: {
		filterIsActive: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		scope: {
			type: String,
			default: "general",
		},
		autoSubmit: {
			type: Boolean,
			default: false,
		},
		filter: {
			type: Object,
			default: () => {},
		},
		previousCasino: {
			type: Object,
			default: () => {},
		},
	},
	components: { DatetimePicker, EditComponent },
	mixins: [permissions],
	data() {
		return {
			valid: true,
			rules: {
				lowerThanTo: (v) => {
					if (!this.form.amount_to || !this.form.amount_from) return true;
					return (
						Number.parseInt(v) <= Number.parseInt(this.form.amount_to) ||
						"Must be lower or equal to amount from"
					);
				},
				higherThanFrom: (v) => {
					if (!this.form.amount_to || !this.form.amount_from) return true;
					return (
						Number.parseInt(v) >= Number.parseInt(this.form.amount_from) ||
						"Must be higher or equal to amount from"
					);
				},
				...defaultRules,
			},
			timeEpoch: new Date(0),
			formExpanses: [],
			currencyCodes,
			filterLoading: true,
		};
	},
	computed: {
		categories: {
			get() {
				return this.form.selectedCategories ?? [];
			},
			set(e) {
				this.form.selectedCategories = e;
			},
		},
		...mapGetters("jackpotGroup2", {
			groupList: "getFilteredGroups",
		}),
		...mapGetters("jackpotLevel2", {
			levelList: "list",
		}),
		...mapGetters({
			_currentCasino: "currentCasino",
		}),
		...mapGetters("meta2", {
			games: "games",
			casinos: "casinos",
		}),
		...mapGetters({
			allowedCasinos: "allowedCasinos",
			allowedCategories: "allowedCategories",
			casinoCategoryMap: "casinoCategoryMap",
		}),
		filteredCasinos() {
			if (this.categories.length === 0) {
				return this.allowedCasinos;
			}
			const cass = _.intersection(
				...this.categories.reduce((agg, curr) => {
					agg.push(
						this.casinoCategoryMap.get(curr)
							? this.casinoCategoryMap.get(curr).map((el) => el.casino_id)
							: []
					);
					return agg;
				}, [])
			);
			return this.allowedCasinos.filter((el) => cass.includes(el.casino_id));
		},
		hasLevels() {
			return this._usrFlagsSome({
				key: "bonus.levels.list",
				val: this.permissions.READ,
			});
		},
		form: {
			get() {
				return this.filter;
			},
			set(val) {
				this.$emit("filter", val);
			},
		},
		filteredLevelList() {
			return this.levelList.map((el) => el.name);
		},
		currentCasino() {
			return this.casinos?.find((el) => el.casino_id === this._currentCasino);
		},
		pickerType() {
			return this.scope === "monthly" ? "month" : "date";
		},
		createdDateMax() {
			if (!this.form.created_to) return;
			return new Date(this.form.created_to);
		},
		createdDateMin() {
			if (!this.form.created_from) return new Date(0);
			return new Date(this.form.created_from);
		},
		collectedDateMax() {
			if (!this.form.collected_to) return;
			return new Date(this.form.collected_to);
		},
		collectedDateMin() {
			if (!this.form.collected_from) return new Date(0);
			return new Date(this.form.collected_from);
		},
	},
	methods: {
		...mapActions("jackpotGroup2", {
			loadGroups: "loadList",
			loadFilteredGroups: "loadFilteredGroups",
		}),
		...mapActions("jackpotLevel2", {
			loadLevels: "loadList",
		}),
		...mapActions("categories", {
			loadCategories: "loadList",
		}),
		addCategoryCasinos(item) {
			const categoryCasinos = (this.casinoCategoryMap.get(item) ?? []).map(
				(el) => el.casino_id
			);
			const casinos = this.form.selectedCasinos ?? [];
			this.form.selectedCasinos = Array.from(
				new Set([...categoryCasinos, ...casinos])
			);
		},
		removeCategoryCasinos(item) {
			const categoryCasinos = (this.casinoCategoryMap.get(item) ?? []).map(
				(el) => el.casino_id
			);
			const casinos = this.form.selectedCasinos ?? [];
			this.form.selectedCasinos = [
				...casinos.filter((el) => !categoryCasinos.includes(el)),
			];
		},
		categoryItemCount(item) {
			if (this.categories.length < 1) {
				return this.casinoCategoryMap.get(item)?.length ?? 0;
			} else {
				const casInCat = this.categories.map(
					(el) => this.casinoCategoryMap.get(el) ?? []
				);
				return _.intersection(this.casinoCategoryMap.get(item), ...casInCat)
					.length;
			}
		},
		inCasinosCount(item) {
			return _.intersection(
				(this.casinoCategoryMap.get(item) ?? []).map((el) => el.casino_id),
				this.form.selectedCasinos
			).length;
		},
		async emitFilter() {
			await this.$refs.form.validate();
			if (!this.valid) return;
			this.$emit("update", this.form);
		},
		removeElement(index, from) {
			this.form[`${from}`] = this.form[`${from}`].filter(
				(_, el_index) => el_index != index
			);
		},
		toggleAllText(to, from) {
			return this.form[`${to}`].length === this[`${from}`].length;
		},
		toggleAllOptions(to, from, partial) {
			this.$nextTick(() => {
				if (this.form[`${to}`].length === this[`${from}`].length) {
					this.form[`${to}`] = [];
				} else {
					this.form[`${to}`] = partial
						? this[`${from}`].map((el) => el[`${partial}`])
						: [...this[`${from}`]];
				}
			});
		},
		async clearFilter() {
			this.$nextTick(() => {
				this.form.showUncollected = true;
				this.form.showCollected = true;
				this.form.collected_from = undefined;
				this.form.collected_to = undefined;
				this.form.created_from = undefined;
				this.form.created_to = undefined;
				this.form.amount_from = undefined;
				this.form.amount_to = undefined;
				this.form.selectedLevels = [];
				this.form.selectedGroups = [];
				this.form.selectedCategories = [];
				this.form.selectedCasinos = [this._currentCasino];
				this.form.selectedCurrencies = [];
			});
			await this.$nextTick();
			this.$emit("update", this.form);
		},
		clearCreated() {
			this.form.created_from = undefined;
			this.form.created_to = undefined;
		},
		clearCollected() {
			this.form.collected_from = undefined;
			this.form.collected_to = undefined;
		},
		...mapActions("meta2", {
			loadStoreGames: "loadGames",
		}),
		async updateFilterData() {
			this.filterLoading = true;
			//Fix this shit
			await this.loadGroups({ query: { per_page: 5000, page: 1 } });
			if (this.hasLevels) {
				await this.loadLevels({ query: { per_page: 5000, page: 1 } });
			}

			this.filterLoading = false;
		},
		dateFormatter(val) {
			if (val.length === 0) return "Select from";
			if (val.length === 1) return "Select to";
			if (val.length === 2) return val.join(" - ");
		},
	},
	watch: {
		form: {
			async handler(val) {
				this.$emit("filter", val);
				if (!this.autoSubmit) return;
				await this.$refs.form.validate();
				if (!this.valid) return;
				this.$emit("update", val);
			},
			deep: true,
		},
		categories: {
			handler(newV, oldV) {
				if (_.isEqual(_.sortBy(newV), _.sortBy(oldV))) return;
				const cass = newV.reduce((agg, curr) => {
					agg.push(
						this.casinoCategoryMap.get(curr)
							? this.casinoCategoryMap.get(curr).map((el) => el.casino_id)
							: []
					);
					return agg;
				}, []);
				this.form.selectedCasinos = _.intersection(...cass);
			},
			deep: true,
		},
	},
	async created() {
		this.filterLoading = true;
		const promises = [];
		promises.push(this.loadCategories());

		if (this.hasLevels) {
			promises.push(this.loadLevels({ query: { per_page: 5000, page: 1 } }));
		}

		promises.push(
			this.loadFilteredGroups({
				payload: {
					casino_id: { of: this.allowedCasinos.map((el) => el.casino_id) },
				},
				query: { per_page: 5000, page: 1 },
			})
		);
		await Promise.all(promises);
		this.filterLoading = false;
		this.$emit("update", this.form);
	},
};
</script>

<style>
.selectall {
	min-height: 48px;
	cursor: pointer;
}

.selectall:hover::after {
	background-color: currentColor;
	bottom: 0px;
	content: "";
	left: 0;
	opacity: 0.1;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>

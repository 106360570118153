var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Paid_bonuses", fluid: "", tag: "section" } },
    [
      _c(
        "v-overlay",
        {
          attrs: { "z-index": "60" },
          model: {
            value: _vm.loaderlay,
            callback: function ($$v) {
              _vm.loaderlay = $$v
            },
            expression: "loaderlay",
          },
        },
        [
          _c(
            "v-progress-circular",
            {
              staticClass: "l2",
              attrs: { indeterminate: "", size: "100", color: "primary" },
            },
            [
              _c("v-progress-circular", {
                staticClass: "l3",
                attrs: { indeterminate: "", size: "75", color: "primary" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "FilterSideBar",
        {
          key: _vm.currentCasino,
          ref: "filterSide",
          attrs: {
            refreshButton: true,
            loading: _vm.loading,
            width: _vm.isMobile ? "" : "450px",
            filterIsEmpty: _vm.filterIsEmpty,
          },
          on: {
            refresh: _vm._loadTable,
            opened: _vm.updateFilterData,
            clear: _vm.onClear,
          },
        },
        [
          _c("FilterForm", {
            ref: "filterForm",
            attrs: { loading: _vm.loading, filterIsActive: _vm.filterIsActive },
            on: { useFilter: _vm.useFilter, update: _vm.onFilterUpdate },
            model: {
              value: _vm.sessionFilter,
              callback: function ($$v) {
                _vm.sessionFilter = $$v
              },
              expression: "sessionFilter",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material true pa-3" },
                [
                  _c(
                    "card-heading",
                    { attrs: { color: "secondary", title: "Paid bonuses" } },
                    [
                      _vm._t("addItem"),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", color: "primary darken-2" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on: tooltip }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          elevation: "1",
                                          fab: "",
                                          small: "",
                                          color: "primary",
                                        },
                                        on: { click: _vm.onExportOpen },
                                      },
                                      tooltip
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-arrow-down-bold-circle"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Export tickets")])]
                      ),
                    ],
                    2
                  ),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.list,
                      options: _vm.options,
                      "server-items-length": _vm.total_records,
                      "item-class": _vm.itemClass,
                      "must-sort": "",
                      "sort-by": "created",
                      "sort-desc": true,
                      loading: _vm.loading,
                      "footer-props": _vm.footerProps,
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function ({ pagination }) {
                            return [
                              _c(
                                "v-data-footer",
                                _vm._b(
                                  {
                                    staticClass: "anton",
                                    attrs: {
                                      options: _vm.options,
                                      pagination: pagination,
                                    },
                                    on: {
                                      "update:options": function ($event) {
                                        _vm.options = $event
                                      },
                                    },
                                  },
                                  "v-data-footer",
                                  _vm.footerProps,
                                  false
                                )
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.level_name`,
                          fn: function ({ item }) {
                            return [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  _vm._s(_vm._f("Capitalize")(item.level_name))
                                ),
                              ]),
                              _vm.getBorder(item) != false
                                ? _c("v-btn", {
                                    staticClass: "elevation-0",
                                    style: {
                                      width: `20px`,
                                      height: `20px`,
                                      background:
                                        _vm.getBorder(item) + "border-box",
                                    },
                                    attrs: { fab: "" },
                                    on: { click: _vm.rotateMe },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: `item.amount`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("Number")(item.amount, {
                                      currency: item.currency,
                                    })
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.player_mongo_id`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "hoverTheme body-2 font-weight-bold primary--text pa-1",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.toPlayer(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.player_mongo_id) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.created`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm._f("Date")(item.created)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.collected`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("Date")(item.collected)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: {
            "click:outside": _vm.exportClose,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.exportClose.apply(null, arguments)
            },
          },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c("ExportDialog", {
            ref: "ticketsExport",
            attrs: {
              builder: "tickets",
              data: _vm.ticketsData,
              filename: "testExport.csv",
            },
            on: { close: _vm.exportClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
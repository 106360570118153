var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-0 mt-0" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-3 mb-0 pb-0" },
            [
              _vm.form.created_from || _vm.form.created_to
                ? _c(
                    "v-icon",
                    {
                      staticClass: "primary--text",
                      on: { click: _vm.clearCreated },
                    },
                    [_vm._v(" mdi-close ")]
                  )
                : _vm._e(),
              _vm._v(" Created "),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "py-0 my-0" },
            [
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "6" } },
                [
                  _c("DatetimePicker", {
                    key: _vm.form.created_from,
                    attrs: {
                      title: "After",
                      createdNow: false,
                      min: _vm.timeEpoch,
                      max: _vm.createdDateMax,
                    },
                    model: {
                      value: _vm.form.created_from,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "created_from", $$v)
                      },
                      expression: "form.created_from",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "6" } },
                [
                  _c("DatetimePicker", {
                    key: _vm.form.created_to,
                    attrs: {
                      title: "Before",
                      createdNow: false,
                      min: _vm.createdDateMin,
                    },
                    model: {
                      value: _vm.form.created_to,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "created_to", $$v)
                      },
                      expression: "form.created_to",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "mt-0 mb-0 pb-0" },
                  [
                    _vm.form.collected_from || _vm.form.collected_to
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "primary--text",
                            on: { click: _vm.clearCollected },
                          },
                          [_vm._v(" mdi-close ")]
                        )
                      : _vm._e(),
                    _vm._v(" Collected "),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "6" } },
                [
                  _c("DatetimePicker", {
                    key: _vm.form.collected_from,
                    attrs: {
                      disabled:
                        this.form.showUncollected && !this.form.showCollected,
                      title: "After",
                      min: _vm.timeEpoch,
                      createdNow: false,
                      max: _vm.collectedDateMax,
                    },
                    model: {
                      value: _vm.form.collected_from,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "collected_from", $$v)
                      },
                      expression: "form.collected_from",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 my-0", attrs: { cols: "6" } },
                [
                  _c("DatetimePicker", {
                    key: _vm.form.collected_to,
                    attrs: {
                      disabled:
                        this.form.showUncollected && !this.form.showCollected,
                      title: "Before",
                      createdNow: false,
                      min: _vm.collectedDateMin,
                    },
                    model: {
                      value: _vm.form.collected_to,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "collected_to", $$v)
                      },
                      expression: "form.collected_to",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.hasLevels
                    ? _c("EditComponent", {
                        key: _vm.groupList.length,
                        attrs: {
                          noDialog: "",
                          fullArray: _vm.groupList,
                          loading: _vm.filterLoading || _vm.loading,
                          width: 500,
                          title: "Edit Groups",
                          placeholderText: "Groups",
                          color: "primary",
                        },
                        model: {
                          value: _vm.form.selectedGroups,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "selectedGroups", $$v)
                          },
                          expression: "form.selectedGroups",
                        },
                      })
                    : _vm._e(),
                  _vm.hasLevels
                    ? _c("EditComponent", {
                        key: _vm.filteredLevelList.length,
                        attrs: {
                          noDialog: "",
                          fullArray: _vm.filteredLevelList,
                          loading: _vm.filterLoading || _vm.loading,
                          width: 500,
                          title: "Edit Levels",
                          placeholderText: "Levels",
                          color: "primary",
                        },
                        model: {
                          value: _vm.form.selectedLevels,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "selectedLevels", $$v)
                          },
                          expression: "form.selectedLevels",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              rules: [
                                _vm.rules.positiveInteger,
                                _vm.rules.lowerThanTo,
                              ],
                              clearable: "",
                              value: _vm.form.amount_from,
                              label: "Amount from",
                            },
                            on: {
                              "click:clear": function ($event) {
                                _vm.form.amount_from = undefined
                              },
                              change: (e) =>
                                Number.parseInt(e) >= 0
                                  ? (_vm.form.amount_from = Number.parseInt(e))
                                  : (_vm.form.amount_from = undefined),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              clearable: "",
                              type: "number",
                              rules: [
                                _vm.rules.positiveInteger,
                                _vm.rules.higherThanFrom,
                              ],
                              value: _vm.form.amount_to,
                              label: "Amount to",
                            },
                            on: {
                              "click:clear": function ($event) {
                                _vm.form.amount_to = undefined
                              },
                              change: (e) =>
                                Number.parseInt(e) >= 0
                                  ? (_vm.form.amount_to = Number.parseInt(e))
                                  : (_vm.form.amount_to = undefined),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("EditComponent", {
                    attrs: {
                      noDialog: "",
                      fullArray: _vm.currencyCodes,
                      loading: _vm.filterLoading || _vm.loading,
                      title: "Edit Currencies",
                      placeholderText: "Currencies",
                      color: "primary",
                    },
                    model: {
                      value: _vm.form.selectedCurrencies,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "selectedCurrencies", $$v)
                      },
                      expression: "form.selectedCurrencies",
                    },
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      multiple: "",
                      clearable: "",
                      "deletable-chips": "",
                      "small-chips": "",
                      label: "Categories",
                      items: _vm.allowedCategories,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function ({ item, on, attrs }) {
                          return [
                            _c(
                              "v-list-item",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.categoryItemCount(item) === 0,
                                    },
                                  },
                                  "v-list-item",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _c("div", [_vm._v(_vm._s(item))]),
                                    ]),
                                    _c("v-spacer"),
                                    _c(
                                      "v-list-item-subtitle",
                                      [
                                        _c(
                                          "v-chip",
                                          {
                                            attrs: {
                                              small: "",
                                              color: `${
                                                _vm.categories.includes(item)
                                                  ? "success"
                                                  : ""
                                              }`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.inCasinosCount(item)
                                                ) +
                                                "/" +
                                                _vm._s(
                                                  _vm.categoryItemCount(item)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-2 elevation-0",
                                            attrs: {
                                              fab: "",
                                              color: "primary",
                                              width: "20",
                                              height: "20",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.addCategoryCasinos(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("mdi-plus")])],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-1 elevation-0",
                                            attrs: {
                                              color: "primary",
                                              fab: "",
                                              width: "20",
                                              height: "20",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.removeCategoryCasinos(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("mdi-minus")])],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.categories,
                      callback: function ($$v) {
                        _vm.categories = $$v
                      },
                      expression: "categories",
                    },
                  }),
                  _c("EditComponent", {
                    attrs: {
                      noDialog: "",
                      fullArray: _vm.filteredCasinos,
                      searchIn: [`casino_id`],
                      valueKey: "casino_id",
                      labelKey: "website",
                      width: 500,
                      title: "Edit Casino List",
                      placeholderText: "Casinos",
                      color: "primary",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "appendItem",
                        fn: function ({ item }) {
                          return [
                            _c("v-chip", { attrs: { small: "" } }, [
                              _vm._v("ID: " + _vm._s(item.casino_id)),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.form.selectedCasinos,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "selectedCasinos", $$v)
                      },
                      expression: "form.selectedCasinos",
                    },
                  }),
                  _c(
                    "v-row",
                    { staticClass: "d-flex justify-center mt-2" },
                    [
                      _c("v-checkbox", {
                        staticClass: "mt-0 mr-3",
                        attrs: {
                          disabled:
                            this.form.collected_from || this.form.collected_to,
                          inset: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "ml-3" }, [
                                  _vm._v("Show Uncollected"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.form.showUncollected,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "showUncollected", $$v)
                          },
                          expression: "form.showUncollected",
                        },
                      }),
                      _c("v-checkbox", {
                        staticClass: "mt-0",
                        attrs: { inset: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "ml-3" }, [
                                  _vm._v("Show Collected"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.form.showCollected,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "showCollected", $$v)
                          },
                          expression: "form.showCollected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center mt-2" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary",
              attrs: {
                disabled: _vm.filterLoading || _vm.loading || !_vm.valid,
                loading: _vm.filterLoading || _vm.loading,
              },
              on: { click: _vm.emitFilter },
            },
            [_vm._v(" Apply ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }